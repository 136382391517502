// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {
  ColorParam,
  ColorStyleParam,
  ComponentClientSpecMapEntry,
  ComponentRef,
  FontStyleParam,
  StyleParam,
  TPAPublicData,
  TPAPublicDataScope,
  TPAPublicDataValue,
} from '@wix/platform-editor-sdk';
import type {StyleParams as EditorStyleParams} from '@wix/editor-platform-sdk-types/dist/types/tpaStyleParams';
import type {FontStyleParamData, SiteTextPresets} from '@wix/editor-platform-sdk-types';

export enum StoresWidgetID {
  CART_ICON = '1380bbc4-1485-9d44-4616-92e36b1ead6b',
  SIDE_CART = '49dbb2d9-d9e5-4605-a147-e926605bf164',
}

export async function migrateMiniCartSettings(sdk: {
  getComponent: (widgetId: string) => Promise<ComponentClientSpecMapEntry>;
  getCompRef: (component: ComponentClientSpecMapEntry) => Promise<ComponentRef>;
  getStyleParams: (ref: ComponentRef) => Promise<EditorStyleParams>;
  getSiteTextPresets: (ref: ComponentRef) => Promise<SiteTextPresets>;
  setStyleParams: (ref: ComponentRef, styleParams: StyleParam[]) => Promise<void>;
  getTexts: (ref: ComponentRef) => Promise<TPAPublicData>;
  setTexts: (options: {
    compRef: ComponentRef;
    scope: TPAPublicDataScope;
    key: string;
    value: TPAPublicDataValue;
  }) => Promise<void>;
  getAppSettingsByExternalId: (externalId: string) => Promise<{[key: string]: {[subKey: string]: TPAPublicDataValue}}>;
  isMultilingualEnabled: () => Promise<boolean>;
}) {
  const cartIconComp = await sdk.getComponent(StoresWidgetID.CART_ICON);
  if (!cartIconComp) {
    return;
  }
  const cartIconCompRef = await sdk.getCompRef(cartIconComp);
  if (!cartIconCompRef) {
    return;
  }

  const sideCartComp = await sdk.getComponent(StoresWidgetID.SIDE_CART);
  if (!sideCartComp) {
    return;
  }
  const sideCartCompRef = await sdk.getCompRef(sideCartComp);
  if (!sideCartCompRef) {
    return;
  }

  const miniCartStyleParams = await sdk.getStyleParams(cartIconCompRef);
  const miniCartTextPresets = await sdk.getSiteTextPresets(cartIconCompRef);

  const colorParams = getMappedMiniCartColorParams(miniCartStyleParams?.colors);
  const fontParams = getMappedMiniCartFontParams(miniCartStyleParams?.fonts, miniCartTextPresets);
  const booleanParams = getMappedMiniCartBooleanParams();
  const numberParams = getMappedMiniCartNumbersParams(miniCartStyleParams?.fonts);

  await sdk.setStyleParams(sideCartCompRef, [...colorParams, ...fontParams, ...booleanParams, ...numberParams]);

  const miniCartTexts = await sdk.getTexts(cartIconCompRef);

  const setTextKeys = async (toSet: {[key: string]: TPAPublicDataValue}) => {
    const setTextPromises = Object.entries(toSet)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => {
        return sdk.setTexts({compRef: sideCartCompRef, key, value, scope: 'COMPONENT'});
      });

    return Promise.all(setTextPromises);
  };

  const miniCartStrings = {
    CART_TITLE: miniCartTexts.APP?.MINI_CART_HEADER_TEXT,
    CART_EMPTY_CART: miniCartTexts.APP?.MINI_CART_EMPTY_CART,
    SIDE_CART_CART_BUTTON: miniCartTexts.APP?.MINI_CART_BUTTON_TEXT,
  };

  await setTextKeys(miniCartStrings);

  await sdk.setTexts({compRef: sideCartCompRef, key: 'DONT_SHOW_SIDE_CART_ON_MOBILE', value: true, scope: 'APP'});

  const isMultilingualEnabled = await sdk.isMultilingualEnabled();

  if (!isMultilingualEnabled) {
    return;
  }
  const mlKeys = await sdk.getAppSettingsByExternalId(cartIconComp.referenceId);
  const languageKeys = Object.keys(mlKeys).filter((key) => key.startsWith('multilingual_'));
  const languagePromises = languageKeys.map((languageKey: string) => {
    const language = languageKey.split('_')[1];
    const miniCartKeys = mlKeys[languageKey];

    const miniCartMap = {
      [toMultilingualKey('CART_TITLE', language)]: miniCartKeys?.MINI_CART_HEADER_TEXT,
      [toMultilingualKey('CART_EMPTY_CART', language)]: miniCartKeys?.MINI_CART_EMPTY_CART,
      [toMultilingualKey('SIDE_CART_CART_BUTTON', language)]: miniCartKeys?.MINI_CART_BUTTON_TEXT,
    };
    return setTextKeys(miniCartMap);
  });

  await Promise.all(languagePromises);
}

function toMultilingualKey(key: string, language: string) {
  return `${key}▶︎l:${language}`;
}

function getMappedMiniCartColorParams(minCartColors: {[key: string]: ColorParam}) {
  const colorsMap = {
    titleTextColor: ['cartTitleColor', 'cartSubtitleColor'],
    titleBackgroundColor: ['cartHeaderBackgroundColor'],
    bodyTextColor: ['cartItemNameColor', 'cartItemDiscountNameColor', 'cartItemMoreDetailsColor'],
    bodyBackgroundColor: ['cartItemsBackgroundColor'],
    dividerColor: ['cartItemsDividerColor'],
    buttonTextColor: ['goToCartButtonFontColor'],
    buttonBackgroundColor: ['goToCartButtonBackgroundColor'],
    buttonBorderColor: ['goToCartButtonBorderColor'],
    closeIconColor: ['cartCloseButtonColor'],
  };

  const color11 = 'color_11';
  const color12 = 'color_12';
  const color15 = 'color_15';
  const color18 = 'color_18';

  const aThemeColor = (themeName: string) => ({themeName, value: ''});
  const getMiniCartColor = (minCartColor: ColorParam, defaultColor: string) =>
    minCartColor ?? aThemeColor(defaultColor);

  const miniCartColors = {
    titleTextColor: getMiniCartColor(minCartColors?.miniCart_headerTextColor, color11),
    bodyTextColor: getMiniCartColor(minCartColors?.miniCart_textColor, color15),
    titleBackgroundColor: getMiniCartColor(minCartColors?.miniCart_headerBackground, color15),
    bodyBackgroundColor: getMiniCartColor(minCartColors?.miniCart_background, color11),
    dividerColor: getMiniCartColor(minCartColors?.miniCart_dividersColor, color12),
    buttonTextColor: getMiniCartColor(minCartColors?.cartWidgetButton_textColor, color11),
    buttonBackgroundColor: getMiniCartColor(minCartColors?.cartWidgetButton_backgroundColor, color18),
    buttonBorderColor: getMiniCartColor(minCartColors?.cartWidgetButton_borderColor, color15),
    closeIconColor: getMiniCartColor(undefined, color11),
  };

  return mapSourceToTargets(colorsMap, (src, target) => ({
    type: 'color',
    key: target,
    param: mapEcomColorToSdkColor(miniCartColors[src]),
  }));
}

function getMappedMiniCartBooleanParams() {
  const booleansMap = {
    showCartTitle: ['CART_TITLE_VISIBILITY'],
    showNumberOfItemsInCart: ['CART_NUMBER_OF_CART_ITEMS_VISIBILITY'],
    showQuantitySelector: ['CART_ITEM_QUANTITY_COUNTER_VISIBILITY'],
    showImage: ['CART_ITEM_IMAGE_VISIBILITY'],
    showItemDetails: ['CART_ITEM_INFO'],
    showDisclaimer: ['CART_SUMMARY_DISCLAIMER_VISIBILITY'],
    showSecureCheckout: ['CART_SUMMARY_SECURE_BADGE_VISIBILITY'],
    showGoToCheckoutButton: ['CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY'],
    showGoToCartButton: ['CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY'],
  };

  const sideCartSettingsBooleans = {
    showCartTitle: true,
    showNumberOfItemsInCart: true,
    showQuantitySelector: true,
    showImage: true,
    showItemDetails: true,
    showDisclaimer: false,
    showSecureCheckout: false,
    showGoToCheckoutButton: false,
    showGoToCartButton: true,
  };

  return mapSourceToTargets(booleansMap, (src, target) => ({
    type: 'boolean',
    key: target,
    param: {value: sideCartSettingsBooleans[src]},
  }));
}

function getMappedMiniCartFontParams(
  miniCartFonts: {
    [key: string]: FontStyleParamData;
  },
  miniCartTextPresets: SiteTextPresets
) {
  const fontsMap = {
    titleTextFontForTitle: ['cartTitleFont'],
    titleTextFontForSubtitle: ['cartSubtitleFont'],
    bodyTextFontForItemName: ['cartItemNameFont'],
    bodyTextFontForDiscountName: ['cartItemDiscountNameFont'],
    bodyTextFontForMoreDetails: ['cartItemMoreDetailsFont'],
    buttonTextFont: ['goToCartButtonFont'],
  };

  const getFontFamily = (preset: string) => {
    return miniCartTextPresets[preset].fontFamily;
  };

  const bodyMFontFamily = getFontFamily('Body-M');
  const bodyLFontFamily = getFontFamily('Body-L');

  const style = {
    bold: false,
    italic: false,
    underline: false,
  };

  const createFontStyle = (family: string, miniCartFontStyle?: any) => ({
    family,
    style,
    fontStyleParam: true,
    ...miniCartFontStyle,
  });

  const customPreset = 'Custom';
  const sideCartOverride = {
    cartTitleFont: {preset: customPreset, size: 23},
    cartSubtitleFont: {preset: customPreset, size: 16},
    cartItemNameFont: {preset: customPreset, size: 16},
    cartItemDiscountNameFont: {preset: customPreset, size: 14},
    cartItemMoreDetailsFont: {preset: customPreset, size: 14},
    goToCartButtonFont: {preset: customPreset, size: miniCartFonts?.cartWidgetButton_textFont?.size ?? 16},
  };

  const fonts = {
    titleTextFontForTitle: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_headerTextFontStyle),
    titleTextFontForSubtitle: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_headerTextFontStyle),
    bodyTextFontForItemName: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_textFontStyle),
    bodyTextFontForDiscountName: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_textFontStyle),
    bodyTextFontForMoreDetails: createFontStyle(bodyMFontFamily, miniCartFonts?.miniCart_textFontStyle),
    buttonTextFont: createFontStyle(bodyLFontFamily, miniCartFonts?.cartWidgetButton_textFont),
  };

  return mapSourceToTargets(
    fontsMap,
    (src, target) =>
      ({
        type: 'font',
        key: target,
        param: {value: {...fonts[src], ...sideCartOverride[target]}},
      } as unknown as FontStyleParam)
  );
}

function getMappedMiniCartNumbersParams(miniCartFonts: {[key: string]: FontStyleParamData}) {
  const numbersParams = {
    cornerRadius: ['goToCartButtonCornerRadius'],
    borderWidth: ['goToCartButtonBorderWidth'],
  };

  const numbers = {
    cornerRadius: parseInt(miniCartFonts?.cartWidgetButton_cornersRadius?.value ?? '0', 10),
    borderWidth: parseInt(miniCartFonts?.cartWidgetButton_borderSize?.value ?? '0', 10),
  };

  return mapSourceToTargets(numbersParams, (src, target) => ({
    type: 'number',
    key: target,
    param: {value: numbers[src]},
  }));
}

function mapSourceToTargets<T extends StyleParam>(
  srcToTargets: {[key: string]: string[]},
  fn: (src: string, target: string) => T
) {
  return Object.entries(srcToTargets).flatMap(([src, targets]) => targets.map((target: string) => fn(src, target)));
}

export function mapEcomColorToSdkColor(param: ColorParam): ColorStyleParam['param'] {
  // eslint-disable-next-line prefer-named-capture-group
  const opacity = param.value.includes('rgba') ? Number(param.value.replace(/^.*,(.+)\)/, '$1')) : 1;
  return {
    value: param.themeName
      ? {
          color: {value: 'val', name: param.themeName},
          opacity,
        }
      : {
          color: false,
          rgba: param.value,
          opacity,
        },
  };
}
